<template>
  <q-page class="" padding>
    <on-demand-form
      :labels="labels"
      :options="options"
      :location-query="stationSearch"
      :mapper="trainMapper"
      :user="user"
      :initial="initial"
      :saved="false"
      :railcard-options="railcardOptions"
      time
      type="fas fa-train"
      @submit="onSubmit"
    />
  </q-page>
</template>

<script>
import date from 'utils/date-time'
const { addToDate, roundMinutesUp } = date
import OnDemandForm from '../form/'
import { trainMapper } from '../form/mappers'
import { stations as stationSearch, railcards as getRailcards } from 'api/train'
import { mapGetters } from 'vuex'
export default {
  components: {
    OnDemandForm
  },
  data () {
    return {
      labels: {
        location: {
          origin: this.$t('going_from'),
          destination: this.$t('going_to'),
          icons: {
            origin: 'fa fa-train',
            destination: 'fa fa-train'
          }
        },
        outbound: {
          date: this.$t('date.travel'),
          time: this.$t('time.from')
        },
        inbound: {
          date: this.$t('date.return'),
          time: this.$t('time.return')
        },
        submit: this.$tc('find.train')
      },
      initial: {
        departMin: date.newDate(),
        origin: {},
        destination: {},
        date: {
          outbound: roundMinutesUp(addToDate(date.newDate(), { minutes: 15 }), 15),
          inbound: null
        },
        options: {
          adults: 1,
          children: 0
        }
      },
      options: {
        adults: [
          {
            label: '1',
            value: 1
          },
          {
            label: '2',
            value: 2
          },
          {
            label: '3',
            value: 3
          },
          {
            label: '4',
            value: 4
          },
          {
            label: '5',
            value: 5
          },
          {
            label: '6',
            value: 6
          },
          {
            label: '7',
            value: 7
          },
          {
            label: '8',
            value: 8
          },
          {
            label: '9',
            value: 9
          }

        ]
      },
      returning: true,
      locationResults: null,
      activeInput: '',
      railcardOptions: []
    }
  },
  computed: {
    ...mapGetters({ user: 'userLookup' }),
    disabled () {
      if (!this.parameters.origin || !this.parameters.destination) {
        return true
      }
      return false
    },
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    }
  },
  watch: {
    '$route.params.origin': {
      immediate: true,
      deep: true,
      handler (val) {
        if (typeof val === 'object') {
          this.initial.origin = val
        }
      }
    }
  },
  created () {
    this.$store.commit('ondemand/clear')
  },
  async beforeMount () {
    const { data } = await getRailcards()
    this.railcardOptions = data
  },
  methods: {
    stationSearch,
    trainMapper,
    mapRailcards (railcards) {
      const reducer = (accumulator, { code }) => {
        if (Object.keys(accumulator).includes(code)) {
          accumulator[code]++
        } else if (code) {
          accumulator[code] = 1
        }
        return accumulator
      }

      const railcardsToMap = railcards.reduce(reducer, {})
      const railcardsToQuery = {}
      let i = 0
      for (const key in railcardsToMap) {
        railcardsToQuery[`railcards[${i}][code]`] = key
        railcardsToQuery[`railcards[${i}][qty]`] = railcardsToMap[key]
        i++
      }

      return railcardsToQuery
    },
    onSubmit (values) {
      const mapped = {
        ...values,
        railcards: this.mapRailcards(values.railcards),
        depart: values.date.outbound,
        return: values.date.inbound
      }
      this.$store.dispatch('ondemand/stash', { parameters: mapped })
      this.$router.push({ name: 'ondemand-train-depart' })
    }
  }
}
</script>
